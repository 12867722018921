export const understandValidation = {
  order: 0,
  name: "Understand validation",
  keywords: "available review vote accept reject",
  subcategory: "Validate database changes",
  markdown: `# Understand validation

  Contributions to the public layer of Tower Hunt's database go through a validation process before becoming available to other users. Validating is a good way to begin contributing because it only involves voting on the accuracy of proposed datapoints, rather than inputting data directly. Validators get a free look at the newest database info. Validators can also earn {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} and money. These powerful benefits make validation a rewarding activity in its own right!

  {% callout type="tip" %}
  **Tip:** Learn more about {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoints{% /inlineRouterLink %} and {% inlineRouterLink articleId="understand-how-datapoints-become-public" %}how they become public{% /inlineRouterLink %} to round out your understanding of Tower Hunt contributions.
  {% /callout %}

  ## What to expect

  In order to validate, you need three things:

  - A {% inlineRouterLink articleId="verify-your-email-address" %}verified account{% /inlineRouterLink %}
  - A {% inlineRouterLink %}positive credit balance{% /inlineRouterLink %} or a {% inlineRouterLink articleId="add-a-payment-method" %}payment method{% /inlineRouterLink %}
  - At least one {% inlineRouterLink articleId="explored-areas" %}explored area{% /inlineRouterLink %}

  ## Validation stages

  Validation unfolds in three parts:

  - {% inlineRouterLink articleId="obtain-available-validations" %}Obtain available validations{% /inlineRouterLink %}: In this stage, you receive validation invitations and check how many validations are available.
  - {% inlineRouterLink articleId="review-validations" %}Review validations{% /inlineRouterLink %}: Here, you assess the accuracy and credibility of proposed changes.
  - {% inlineRouterLink articleId="vote-on-validations" %}Vote on validations{% /inlineRouterLink %}: Finally, you decide how you feel about each proposed change.

  ## Validation outcomes

  Ultimately, proposed changes are either **approved** or **rejected**. Changes are finalized asynchronously, though, so you will not know the outcome right away. Read on to learn more about outcomes, rewards, and penalties.

  ### Voting threshold

  Proposed changes are finalized based on an analysis of {% inlineRouterLink articleId="vote-on-validations" %}voting{% /inlineRouterLink %} sentiment after a **minimum of 5 {% inlineRouterLink articleId="vote-on-validations" %}votes{% /inlineRouterLink %} have been submitted**. Additionally, Tower Hunt administrators have the option to finalize changes in certain cases.

  ### Approval

  A proposed change is approved if there is sufficient {% inlineRouterLink articleId="vote-on-validations" %}voting{% /inlineRouterLink %} volume and **greater than 60%** of {% inlineRouterLink articleId="vote-on-validations" %}votes{% /inlineRouterLink %} were {% inlineAppIcon iconName="check" /%}**accepts**. Approval triggers the following actions:

  - The user that contributed the approved change reclaims its {% inlineRouterLink articleId="what-is-staking?" %}staked{% /inlineRouterLink %} credits plus a reward, receives a {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} boost, and receives an {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}*Originator* attribution entitling it to a share of revenue{% /inlineRouterLink %}.
  - Each validator that {% inlineRouterLink articleId="vote-on-validations" %}voted{% /inlineRouterLink %} to {% inlineAppIcon iconName="check" /%}**accept** reclaims its {% inlineRouterLink articleId="what-is-staking?" %}staked{% /inlineRouterLink %} credits plus a reward, receives a {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} boost, and receives a {% inlineRouterLink articleId="how-to-earn-on-tower-hunt" %}*Validator* attribution entitling it to a share of revenue{% /inlineRouterLink %}.
  - Each validator that {% inlineRouterLink articleId="vote-on-validations" %}voted{% /inlineRouterLink %} to {% inlineAppIcon iconName="xMark" /%}**reject** forfeits its {% inlineRouterLink articleId="what-is-staking?" %}staked{% /inlineRouterLink %} credits and loses {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %}.
  - The proposed change becomes visible in Tower Hunt's public data layer. The effect of this depends on whether the change was an *addition* or a *removal*.

  ### Rejection

  A proposed change is rejected if there is sufficient {% inlineRouterLink articleId="vote-on-validations" %}voting{% /inlineRouterLink %} volume and **less than 10%** of {% inlineRouterLink articleId="vote-on-validations" %}votes{% /inlineRouterLink %} were {% inlineAppIcon iconName="check" /%}**accepts**. Rejection triggers the following actions:

  - The user that contributed the rejected change forfeits its {% inlineRouterLink articleId="what-is-staking?" %}staked{% /inlineRouterLink %} credits and loses {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %}.
  - Each validator that {% inlineRouterLink articleId="vote-on-validations" %}voted{% /inlineRouterLink %} to {% inlineAppIcon iconName="xMark" /%}**reject** reclaims its {% inlineRouterLink articleId="what-is-staking?" %}staked{% /inlineRouterLink %} credits plus a reward and receives a {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %} boost.
  - Each validator that {% inlineRouterLink articleId="vote-on-validations" %}voted{% /inlineRouterLink %} to {% inlineAppIcon iconName="check" /%}**accept** forfeits its {% inlineRouterLink articleId="what-is-staking?" %}staked{% /inlineRouterLink %} credits and loses {% inlineRouterLink articleId="understand-reputation" %}reputation{% /inlineRouterLink %}.
  - The proposed change remains out of Tower Hunt's public data layer. The user that contributed the rejected change can continue to see it as {% inlineDatapoint state="rejected" /%} and must pay to access it (similar to a {% inlineDatapoint state="safezone" /%}). If the user wants to resubmit the change, it must be {% inlineRouterLink articleId="datapoint-interactions" %}removed{% /inlineRouterLink %} and re-added.

  ## Outcome notification

  When validations are finalized, two parts of your account will update:

  ### Key Actions hub

  Within the {% inlineRouterLink articleId="key-actions-hub" %}Key Actions hub{% /inlineRouterLink %}, your available balance and reputation update in real-time, pulsing with color to indicate that things are happening within Tower Hunt.

  ### Contributions overview

  The {% inlineRouterLink articleId="track-your-earnings" %}contributions overview tab{% /inlineRouterLink %} also updates in real-time to show changes to your available balance and reputation. Additionally, you can {% inlineRouterLink articleId="track-your-earnings" sectionName="Contribution details" %}see the outcome of each of your validations{% /inlineRouterLink %} on this tab.

  ## Next Steps

  As you get more comfortable with validation, revisit {% inlineRouterLink articleId="getting-started-for-tower-hunt-contributors" %}Getting started for Tower Hunt contributors{% /inlineRouterLink %} to keep expanding your upside.`,
};
